import axios from 'axios';
import cookie from "cookie";


axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true //Pass cookies
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
    let token = cookie.parse(document.cookie)['XSRF-TOKEN'];

    if (token) {
        axios.defaults.headers.common['X-XSRF-TOKEN'] = token;
    }
    return config;
});

export default axios