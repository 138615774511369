<template>
<span>
    <div class="card-body text-center text-muted">
        <p v-if="error" class="alert alert-danger text-left" role="alert">{{$t(error)}}</p>
        <stepper v-bind:ascent-color="system_settings.accent_color"></stepper>

        <div id="cover-spin" v-if="loading"></div>
<div class="row">
    <div class="col-md-12 ">
        <p class="header-info">{{$t('PURCHASE_TEXT')}}</p>
    </div>
</div>
        <div class="row ">

            <div class="col-md-6 ">
                <form @submit.prevent="verify" class="text-left">

<!--                    <div class="form-group row">-->
                    <!--                        &lt;!&ndash;                        <label class="col-md-4 col-sm-4 col-form-label">{{$t('BARCODE')}}</label>&ndash;&gt;-->
                    <!--                        <div class="col-md-12 col-sm-8">-->
                    <!--                            <input type="text" :readonly="bar_code" name="bar_code" v-model="bar_code"-->
                    <!--                                   class="form-control col-xs-2" value="" required>-->


                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="row field-info">-->
                    <!--                        &lt;!&ndash;                        <div class="col-md-4"></div>&ndash;&gt;-->
                    <!--                        <p class="col-md-12 text-muted small ">{{$t('REQUESTED_BARCODE')}} </p>-->
                    <!--                    </div>-->

                    <div class="form-group row" v-if="!selected_single_product_ean">
                        <!--                        <label class="col-md-4 col-sm-4 col-form-label">{{$t('PRODUCT')}}</label>-->
                        <div class="col-md-8 col-sm-8" v-if="multiple_eans_selected && (!selected_single_product_ean)">
                            <select  v-model="selected_product_ean" name="product_ean"
                                    class="form-control">
                                <option value=''>{{$t('EMPTY_TEXT')}}</option>
                                <option v-for="ean in product_eans" v-bind:key="ean" :value="ean">
                                    {{ean.label}} ({{ ean.ean }})
                                </option>
                            </select>


                        </div>
                         <div v-if="multiple_eans_selected && (!selected_single_product_ean)"
                              class="col-md-4  px-3 py-1 pl-2">
                        <a @click="changeProductList"
                           :disabled="loading"
                           v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"
                           class="btn btn-info px-1 py-2">{{$t('IMPORT_FROM_DEAL')}}
                        </a>
                    </div>
                        <div v-if="!multiple_eans_selected && (!selected_single_product_ean)"
                             class="col-md-12 col-sm-8">

                            <v-select :placeholder="$t('EMPTY_TEXT')"  v-model="selected_product_ean" :value="selected_product_ean"
                                      label="name" :filterable="false" :options="options" @search="onSearch">
                                <template slot="no-options">
                                  {{$t('PRO_SEARCH_TEXT')}}
                                </template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.label}} ({{option.ean}})
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">

                                        {{ option.label }} ({{option.ean}})
                                    </div>
                                </template>
                            </v-select>


                        </div>
                        <!--                        <div v-if="selected_single_product_ean" class="col-md-12 col-sm-8">-->

                        <!--                            <div type="text" readonly-->
                        <!--                                 class="form-control-plaintext">{{selected_single_product_ean}}-->
                        <!--                                </div>-->
                        <!--                        </div>-->

                    </div>

                    <div class="row field-info" v-if="!selected_single_product_ean">
                        <!--                        <div class="col-md-4"></div>-->
                        <span class="col-md-12 text-danger small" v-if="validation_errors.selected_product_ean">{{validation_errors.selected_product_ean}}</span>
                        <p class="col-md-12 text-muted small " v-if="!validation_errors.selected_product_ean">
                            {{$t('PR_TO_SELECT')}}</p>
                    </div>
                    <div class="form-group row" v-if="!isFixedDeal">
                        <div class="col-md-7 col-sm-2 col-3">
                            <input type="text" name="price" v-model="price" :placeholder="$t('PRICE_PLACE_HOLDER')"
                                   class="form-control" value="" required>

                        </div>
                        <!--                        <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('PRICE')}}</label>-->
                        <div class="col-md-3 col-sm-2 col-3">
                            <!--                            <input type="text" :readonly="price_currency" name="price_currency"-->
                            <!--                                   v-model="price_currency" class="form-control" value="" required>-->
                            <select v-if="currency_list.length >1" v-model="price_currency" name="product_ean"
                                    class="form-control">
                                <option v-for="code in currency_list" v-bind:key="code" :value="code">
                                    {{code}}
                                </option>
                            </select>
<input v-else-if="currency_list.length === 1" type="text" name="price_currency" v-model="price_currency"
       class="form-control" disabled required>
                        </div>


                    </div>

                    <div class="row field-info" v-if="!isFixedDeal">
                        <!--                        <div class="col-md-4"></div>-->
                        <span class="col-md-12 text-danger small" v-if="validation_errors.price">{{$t(validation_errors.price)}}</span>
                        <p class="col-md-12 text-muted  small " v-if="!validation_errors.price">{{$t('PRODUCT')}}
                            {{$t('PRICE')}} </p>
                    </div>

                    <!--                    <div class="row field-info">-->
                    <!--                        &lt;!&ndash;                        <div class="col-md-4"></div>&ndash;&gt;-->
                    <!--                        <span class="col-md-12 text-danger small" v-if="validation_errors.ben_amount">{{validation_errors.ben_amount}}</span>-->
                    <!--                        <p class="col-md-12 text-muted small" v-if="!validation_errors.ben_amount">-->
                    <!--                            {{$t('BEN_PRICE_TEXT')}}</p>-->
                    <!--                    </div>-->
                    <div class="form-group row">
                        <!--                        <label class="col-md-4 col-sm-4 col-form-label">{{$t('PAYMENT_DATE')}}</label>-->
                        <div class="col-md-8 col-sm-8">
<!--                            <DatePicker input-class="form-control" format="yyyy-MM-dd"-->
                            <!--                                        v-model="payment_date"></DatePicker>-->
 <date-picker v-model="payment_date" valueType="format"></date-picker>

                        </div>
                    </div>

                    <div class="row field-info">
                        <!--                        <div class="col-md-4"></div>-->
                        <span class="col-md-12 text-danger small" v-if="validation_errors.payment_date">{{validation_errors.payment_date}}</span>
                        <p class="col-md-12 text-muted small " v-if="!validation_errors.payment_date">
                            {{$t('PAYMENT_DATE_TEXT')}}</p>
                    </div>

                       <div v-if="ben_amount" class="form-group row">
                            <div class="col-md-12">
                        <div type="text" readonly
                             class="form-control-plaintext"><div class="alert alert-info" role="alert"><i
                                class="glyphicon glyphicon-info-sign"></i>
  {{$t('REFUND_AMOUNT_NOTICE')}} {{ben_amount}} {{price_currency}}
</div>
                                </div>
                                </div>
                           <!--                        <label class="col-md-4 col-sm-4 col-6 col-form-label">{{$t('REFUND_AMOUNT_TEXT')}}</label>-->
                           <!--                        <div class="col-md-6 col-sm-2 col-3">-->
                           <!--                            <input type="text" :readonly="price_currency" name="ben_price_currency"-->
                           <!--                                   v-model="price_currency" class="form-control" value="" required>-->
                           <!--                        </div>-->
                           <!--                        <div class="col-md-6 col-sm-2 col-3">-->
                           <!--                            <input type="text" name="ben_amount" v-model="ben_amount" :readonly="1"-->
                           <!--                                   class="form-control"-->
                           <!--                                   value="">-->
                           <!--                        </div>-->

                    </div>
                    <!--                    <div class="form-group row">-->
                    <!--                        &lt;!&ndash;                        <label class="col-md-4 col-sm-4 col-form-label">{{$t('IBAN')}}</label>&ndash;&gt;-->
                    <!--                        <div class="col-md-9 col-sm-8">-->
                    <!--                            <input type="text" @change="onIBANChange" name="iban" v-model="iban"-->
                    <!--                                   class="form-control"-->
                    <!--                                   col-xs-2 value="" required>-->


                    <!--                        </div>-->
                    <!--                        <div class="col-md-3 col-sm-3" v-if="iban_change">-->
                    <!--                            <button type="button" @click="checkIBAN"-->
                    <!--                                    :disabled="loading"-->
                    <!--                                    v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"-->
                    <!--                                    class="btn btn-info px-4 py-3">{{$t('CHECK')}}-->
                    <!--                            </button>-->
                    <!--                        </div>-->
                    <!--                        <span v-if="iban_error" style="padding-top: 6px" class="alert alert-danger text-center"-->
                    <!--                              role="alert">{{$t(iban_error)}}</span>-->

                    <!--                    </div>-->
                    <!--             -->


                    <!--                    <div class="form-group text-center pt-4">-->
                    <!--                        <button-->
                    <!--                                :disabled="loading"-->
                    <!--                                v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"-->
                    <!--                                type="submit" class="btn btn-info px-4 py-3">{{$t('CONFIRM_BUTTON_TEXT')}}-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </form>
            </div>
            <div class="col-md-6">
                <div class="form-group row" v-if="uploaded_image_url">

                    <div class="col-md-12 col-sm-8">
                        <div class="uploaded_file_view ml-auto mr-auto" style="margin: unset">
                            <img :src="uploaded_image_url"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-actions">
        <div class="form-group text-center pr-4 pt-4 float-right">
        <button @click="verify"
                :disabled="loading"
                v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"
                type="submit" class="btn btn-info next px-4 py-2">{{$t('CONTINUE')}}
        </button>
        </div>
          <div class="form-group text-center pl-4 pt-4 float-left start-over">
        <a href="/"
           :disabled="loading"
        >{{$t('START_OVER')}}
        </a>
        </div>
    </div>
    </span>
</template>
<script>
    import DatePicker from 'vue2-datepicker';
    import {_} from 'vue-underscore';
    import Stepper from './StepperBar.vue';

    export default {
        components: {
            DatePicker,
            Stepper
        },
        data() {
            return {
                selected_product_ean: '',
                bar_code: '',
                ben_amount: '',
                price_currency: '',
                price: '',
                payment_date: '',

                validation_errors: {
                    selected_product_ean: '',
                    ben_amount: '',
                    price: '',
                    payment_date: '',
                    price_currency: '',
                },
                error: false,
                loading: false,
                status: true,
                product_eans: {},
                currency_list: {},
                multiple_eans_selected: false,
                redemption_request_response: false,
                deal_product_eans: false,
                uploaded_image_url: false,
                options: [],
                isFixedDeal: false

            }
        },
        created() {
            this.loading = true
            this.pdfFileUrl = null
            this.axios

                .get('/deal-products-info', {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                .then(response => {
                    if (response.data.statusCode == 0) {
                        if (Object.keys(response.data.selected_product_ean_list).length > 0) {
                            this.product_eans = response.data.selected_product_ean_list;
                            this.deal_product_eans = response.data.product_eans;
                            this.multiple_eans_selected = true;
                        } else {
                            // this.product_eans = response.data.product_eans;
                            this.options = response.data.product_eans;
                        }


                        this.bar_code = response.data.bar_code;
                        this.payment_date = response.data.payment_date;
                        this.uploaded_image_url = response.data.uploaded_voucher_temp_name;
                        this.uploaded_file_type = response.data.uploaded_voucher_file_type;
                      if(this.uploaded_file_type !== undefined && this.uploaded_file_type === 'application/pdf') {
                        this.fileUrl = this.uploaded_image_url;
                        this.renderThumbnails()
                      }
                        if (response.data.product_eans.length === 1) {
                            this.selected_product_ean = response.data.product_eans[0];
                            this.selected_single_product_ean = response.data.product_eans[0]['ean'];
                        } else {
                            this.selected_product_ean = response.data.selected_product_ean;
                        }

                        if (response.data.is_fixed_deal) {
                            this.price = '';
                            this.isFixedDeal = true;
                        } else {
                            this.price = response.data.selected_product_price;
                        }

                        this.ben_amount = response.data.discount_amount;
                        this.currency_list = response.data.currency_list;
                        this.price_currency = response.data.selected_product_currency;
                    } else {
                        this.error = response.data.debug;
                    }
                }).catch((error) => {
                console.log('error :' + error);
            }).finally(() => this.loading = false);

        },
        methods: {
            verify() {

                this.loading = true;
                this.error = false;
                this.validation_errors = {};
                this.axios
                    .post('/redemption-request', {
                        'selected_product_ean': this.selected_product_ean,
                        'price_currency': this.price_currency,
                        'price': this.price,
                        'ben_amount': this.ben_amount,
                        'payment_date': this.payment_date
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'

                        }
                    }).then(response => {
                    if (response.data.statusCode == 0) {
                        this.$router.push({name: 'payment-details'});
                    } else {
                        if (response.data.validation_errors != undefined && Object.keys(response.data.validation_errors).length > 0) {
                            this.validation_errors = response.data.validation_errors;
                        } else {
                            this.error = response.data.debug;
                        }
                    }
                })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },

            startOver() {
                this.$router.push({name: 'home'});
            },
            changeProductList() {
                this.loading = true;
                this.selected_product_ean = null;
                // this.product_eans = this.deal_product_eans;
                this.multiple_eans_selected = false;
                this.loading = false;
            },
            calculateBenefitAmount() {
                this.loading = true;
                this.axios
                    .post('/benefit-amount', {
                        'selected_product_ean': this.selected_product_ean,
                        'price': this.price
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'

                        }
                    }).then(response => {
                    if (response.data.statusCode == 0) {
                        this.ben_amount = response.data.discount_amount;
                        if(response.data.selected_product_currency !== undefined && response.data.selected_product_currency.length >0){
                            this.price_currency = response.data.selected_product_currency;
                        }

                    } else {
                        if (response.data.validation_errors != undefined && Object.keys(response.data.validation_errors).length > 0) {
                            this.validation_errors = response.data.validation_errors;
                        } else {
                            this.error = response.data.debug;
                        }
                    }
                })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },

            onSearch: _.debounce(async function (search, loading) {

                if (search.length >= 3) {

                    await this.axios.post('/search-products', {
                        'search': search
                    }).then(response => {
                        if (response.data.statusCode == 0) {
                            loading(false);
                            this.options = response.data.products;

                        } else {
                            this.error = response.data.debug;
                        }
                        loading(false);

                    }).catch((error) => {
                        console.log('error' + error);
                    })
                }
            }, 400)

        },

        watch: {
            selected_product_ean: function () {
                this.calculateBenefitAmount();
            },
            price: function () {
                this.calculateBenefitAmount();
            }
        },

        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {accent_color: '', accent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }
        }
    }
</script>
<style>
    @import "~vue2-datepicker/index.css";

    .mx-datepicker {
        width: unset;
        display: unset;
    }
</style>