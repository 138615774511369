<template>

<span>
    <div class="card-body text-center text-muted">
        <p v-if="error" class="alert alert-danger text-left" role="alert">{{$t(error)}}</p>
 <stepper v-bind:ascent-color="system_settings.accent_color"></stepper>

        <div id="cover-spin" v-if="loading"></div>

<div class="row">
    <div class="col-md-12 ">
        <p class="header-info">{{$t('LANDING_TEXT')}}</p>
    </div>
</div>

         <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1">
        <form @submit.prevent="verify" class="">
        <div class="row">
        <div class="col-sm-4">
        </div>

        <div class="col-sm-4">
        <div class="input-group">
            <masked-input
                    type="text"
                    name="bar_code"
                    class="form-control input-lg custom-length"
                    v-model="bar_code"
                    :mask="[ /[A-Z ]+/, /[A-Z ]+/,/[A-Z ]+/, /[A-Z ]+/, '-', /\d/, /\d/, /\d/,/\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                    :guide="false"
                    placeholderChar="#" placeholder="SF__-____-____-____">
    </masked-input>
            <!--        <input v-model="bar_code1"-->
            <!--               :class="inputClass[1]"-->
            <!--               v-on:keyup="focusNext(1,2,bar_code1.length)" value="" type="text" id="1"-->
            <!--               class="form-control text-center text-upper" name="code1"-->
            <!--               maxlength="4" placeholder="SFxx">-->
            <!--        <span class="input-group-text mb-0"> - </span>-->
            <!--        <input v-model="bar_code2" value="" v-on:keyup="focusNext(2,3,bar_code2.length)" type="text"-->
            <!--               :class="inputClass[2]"    class="form-control text-center text-upper" name="code2" id="2"-->
            <!--               maxlength="4" placeholder="xxxx">-->
            <!--        <span class="input-group-text"> - </span>-->
            <!--        <input v-model="bar_code3" value="" type="text" v-on:keyup="focusNext(3,4,bar_code3.length)"-->
            <!--               :class="inputClass[3]" class="form-control text-center text-upper" name="code3" id="3"-->
            <!--               maxlength="4" placeholder="xxxx">-->
            <!--        <span class="input-group-text"> - </span>-->
            <!--        <input v-model="bar_code4" value="" v-on:keyup="focusNext(4,0,bar_code4.length)" type="text" class="form-control text-center text-upper" name="code4"-->
            <!--               :class="inputClass[4]"   id="4"-->
            <!--               maxlength="4" placeholder="xxxx">-->
                    </div>
                    </div>
            <div class="col-sm-4">
        </div>
                    </div>
        </form>
    </div>
  </div>

        <div class="row pt-3" v-if="validation_errors.bar_code">
    <div class="col-md-12 ">
        <p class="text-danger">{{validation_errors.bar_code}}</p>
    </div>
</div>
    </div>

    <div class="bottom-actions">
        <div class="form-group text-center pr-4 pt-4 float-right">
         <button @click="verify"
                 :disabled="loading"
                 v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"
                 type="submit" class="btn btn-info next px-4 py-2">{{$t('CONTINUE')}}
                        </button>
            </div>
    </div>
</span>
</template>
<script>
    import Stepper from './StepperBar.vue';
    import MaskedInput from 'vue-text-mask'

    export default {
        data() {
            return {
                bar_code: '',
                // bar_code1: '',
                // bar_code2: '',
                // bar_code3: '',
                // bar_code4: '',
                grecaptcha: '',
                bar_code_formatted: '',
                error: false,
                loading: false,
                status: true,
                validation_errors: {bar_code: '', gcaptcha_token: ''},
                // inputClass: {1: '', 2: '', 3: '', 4: ''},
            }
        },
        created() {
            this.loading = true
            this.axios

                .get('/home', {
                    headers: {
                        'Accept': 'application/json'
                    }
                })

                .then(response => {

                    if (response.data.statusCode === 0) {
                        // document.getElementById('1').focus();
                        if (response.data.bar_code.length > 0) {
                            this.bar_code = response.data.bar_code;
                            this.verify();

                        }

                    } else {
                        this.error = response.data.debug;
                    }
                }).catch((error) => {

                console.log('error' + error);
            }).finally(() => this.loading = false);

        },

        methods: {
            async verify() {
                this.loading = true;
                this.error = false;
                // this.bar_code = '';
                if (this.bar_code!== undefined && this.bar_code.length > 0) {
                    this.bar_code_formatted = this.bar_code.replace(/[-]/g, "")
                }
              /**  if (this.bar_code2 !== undefined) {
                    this.bar_code = this.bar_code.concat(this.bar_code2, '-')
                }
                if (this.bar_code3 !== undefined) {
                    this.bar_code = this.bar_code.concat(this.bar_code3, '-')
                }
                if (this.bar_code4 !== undefined) {
                    this.bar_code = this.bar_code.concat(this.bar_code4)
                }**/
                // this.bar_code = this.bar_code1 + '-' + this.bar_code2 + '-' + this.bar_code3 + '-' + this.bar_code4;
               console.log(this.bar_code);
                await this.$recaptchaLoaded()

                this.grecaptcha = await this.$recaptcha('/')
                this.axios
                    .post('/verify-code', {
                        'bar_code': this.bar_code_formatted.toUpperCase(),
                        'gcaptcha_token': this.grecaptcha
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'

                        }
                    }).then(response => {
                    if (response.data.statusCode == 0) {
                        this.$store.dispatch("fetchValidationCode", this.bar_code)
                        this.$router.push({name: 'upload-voucher'});
                        // }else if(response.data.statusCode == 1) {
                        //     this.$router.push({name: 'check-status'});
                    } else {
                        if (response.data.validation_errors != undefined && Object.keys(response.data.validation_errors).length > 0) {
                            this.validation_errors = response.data.validation_errors;
                        } else {
                            this.error = response.data.debug;
                        }
                    }
                })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            focusNext(id, nextId, modelLength) {

                if (modelLength === 4) {
                    if (nextId !== 0) {
                        document.getElementById(nextId).focus();
                    }
                    this.inputClass[id] = 'border border-success input-border'
                } else if (modelLength === 0) {
                    this.inputClass[id] = ''
                } else {
                    this.inputClass[id] = 'border border-danger input-border'
                }
            }
        },
        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {ascent_color: '', ascent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }

        },
        components: {
            Stepper,
            MaskedInput
            // StepNavigation
        },

    }
</script>

<style>
    .text-upper {
        text-transform: uppercase;
    }


    .input-group-text {
        background-color: transparent;
        border: none;
    }


    .input-border {
        border-width: 2px !important;
    }

    .custom-length{
        letter-spacing: 2px;
    }
</style>