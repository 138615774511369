<template>

    <div id="stepper-wrap">
        <div v-if="['products', 'upload-voucher','payment-details','recap'].includes($route.name)"
             class="stepper-line active"
             v-bind:style="{ backgroundColor: ascentColor  }"></div>
        <div v-else class="stepper-line"></div>
        <div v-if="['products','payment-details','recap'].includes($route.name)" class="stepper-line active"
             v-bind:style="{ backgroundColor: ascentColor  }"></div>
        <div v-else class="stepper-line"></div>
        <div v-if="['payment-details','recap'].includes($route.name)" class="stepper-line active"
             v-bind:style="{ backgroundColor: ascentColor  }"></div>
        <div v-else class="stepper-line"></div>

        <div v-if="$route.name === 'recap'" class="stepper-line active"
             v-bind:style="{ backgroundColor: ascentColor +' !important' }"></div>
        <div v-else class="stepper-line"></div>

        <!-- This is the individual marker-->
        <div class="marker mfirst stepper-icon one active"
             v-bind:style="{ border: '2px solid '+ascentColor }">
            <i v-bind:style="{ color: ascentColor }" class="fa fa-barcode active"></i>
            <div class="stepper-title">{{$t('VALIDATE_CODE')}}</div>
        </div>


        <!-- / marker -->

        <!-- This is the individual marker-->
        <div v-if="['products', 'upload-voucher','payment-details','recap'].includes($route.name)"
             class="marker m2 stepper-icon two active"
             v-bind:style="{ border: '2px solid '+ascentColor }">
            <i v-bind:style="{ color: ascentColor }" class="fa fa-upload active"></i>
            <div class="stepper-title">{{$t('UPLOAD')}} {{$t('RECEIPT')}}</div>
        </div>
        <div v-else class="marker m2 stepper-icon two">
            <i class="fa fa-upload"></i>
            <div class="stepper-title"> {{$t('RECEIPT')}} {{$t('UPLOAD')}}</div>
        </div>
        <!-- / marker -->

        <!-- This is the individual marker-->
        <!--        <div class="marker m3 stepper-icon three">-->
        <!--            <i class="fa fa-send"></i>-->
        <!--        </div>-->
        <!-- / marker -->


        <!-- This is the individual marker-->
        <div v-if="['products','payment-details','recap'].includes($route.name)"
             class="marker m3 stepper-icon four active"
             v-bind:style="{ border: '2px solid '+ascentColor }">
            <i v-bind:style="{ color: ascentColor }" class="fa fa-list active"></i>
            <div class="stepper-title">{{$t('PURCHASE')}} {{$t('DETAILS')}}</div>
        </div>
        <div v-else class="marker m3 stepper-icon four">
            <i class="fa fa-list"></i>
            <div class="stepper-title">{{$t('PURCHASE')}} {{$t('DETAILS')}}</div>
        </div>

        <div v-if="['payment-details','recap'].includes($route.name)" class="marker m4 stepper-icon two active"
             v-bind:style="{ border: '2px solid '+ascentColor }">
            <i v-bind:style="{ color: ascentColor }" class="fa fa-credit-card active"></i>
            <div class="stepper-title">{{$t('BANK')}} {{$t('DETAILS')}}</div>
        </div>
        <div v-else class="marker m4 stepper-icon two">
            <i class="fa fa-credit-card"></i>
            <div class="stepper-title">{{$t('BANK')}} {{$t('DETAILS')}}</div>
        </div>
        <!-- / marker -->

        <div v-if="$route.name === 'recap'" class="marker mlast stepper-icon two active"
             v-bind:style="{ border: '2px solid '+ascentColor }">
            <i v-bind:style="{ color: ascentColor }" class="fa fa-check active"></i>
            <div class="stepper-title">{{$t('CONFIRMATION')}}</div>
        </div>
        <div v-else class="marker mlast stepper-icon two">
            <i class="fa fa-check"></i>
            <div class="stepper-title">{{$t('CONFIRMATION')}}</div>
        </div>


    </div>

</template>

<script>
    export default {
        name: "StepperBar",
        props: ['ascentColor']
    }
</script>

<style scoped>

</style>