<template>
<span>
    <div class="card-body text-center text-muted">
        <p v-if="error" class="alert alert-danger text-left" role="alert">{{ $t(error) }}</p>
        <p v-if="error_warning" class="alert alert-warning text-left" role="alert">{{ $t(error_warning) }}</p>
        <p v-if="file_empty_error" class="alert alert-danger text-left" role="alert">{{ $t('EMPTY_FILE') }}</p>
    <stepper v-bind:ascent-color="system_settings.accent_color"></stepper>

        <div id="cover-spin" v-if="loading"></div>


        <div class="row title-gap">
        <div class="col-md-6 col-sm-10">
<p role="alert" class="alert alert-info">{{ $t('VALID_FILE_TYPES') }}. {{ $t('VALID_FILE_SIZE') }}.</p>
            <p>{{ $t('RECIEPT_TEXT_HEADER') }}.</p>


            <ul class="fa-ul text-left mt-5">
                <li class="li-info"><i class="fa fa-info-circle pr-4 "></i>{{ $t('RECIEPT_TEXT_DATE') }}</li>
  <li class="li-info"><i class="fa fa-info-circle pr-4  "></i>{{ $t('RECIEPT_TEXT_PLACE') }}</li>
  <li class="li-info"><i class="fa fa-info-circle pr-4 "></i>{{ $t('RECIEPT_TEXT_DESCRIPTION') }}</li>
  <li class="li-info"><i class="fa fa-info-circle pr-4  "></i>{{ $t('RECIEPT_TEXT_AMOUNT') }}</li>

</ul>

        </div>
        <div class="col-md-6 col-sm-10">
            <div class="row">
                <div class="col-md-12 col-sm-10">
                         <div class="panel unset-bottom-panel">
                            <div class="btn btn-info" v-bind:style="{'background-color':system_settings.accent_color}">
                                <div class="btn_upload">
                                    <input type="file" @change="onFileChanged" name="">
                                    {{ $t(fieldTextUpload) }}
                                </div>

                            </div>
                        </div>
                </div>

            </div>
            <div class="clearfix"></div>
            <div v-if="!url" class="row pt-5">
                <div @mouseover="sample_image = true" @mouseleave="sample_image = false" class="col-md-12 col-sm-10">

                    <p>{{ $t('SAMPLE_RECEIPT_TEXT') }} <i class="fa fa-hand-o-down pl-3"></i></p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 col-sm-10">
                    <div v-if="url" class="uploaded_file_view" style="margin: 10px auto;max-width: 400px;" id="uploaded_view">
                      <span v-if="fileUrl">

                          <canvas class="the-canvas" :id="_uid"/>
                        <div v-if="pageCount > 1">
                          <div style="padding-bottom: 10px">
                          <button @click="onPrevPage" id="prev">{{ $t('PREVIOUS_PAGE') }}</button>
                          <button @click="onNextPage" id="next">{{ $t('NEXT_PAGE') }}</button>
                          &nbsp; &nbsp;
                          <span>Page: <span id="page_num">{{ pageNum }}</span> / <span id="page_count">{{
                              pageCount
                            }}</span></span>
                            </div>
                          <p  role="alert" class="alert alert-info">{{ $t('MULTIPLE_PAGE_NOTICE') }}.</p>

                        </div>
                         </span>
                                            <img v-else-if="url" :src="url"/>
                      <!--<embed src="https://infolab.stanford.edu/pub/papers/google.pdf" width= "300" height= "375" type="application/pdf">-->
                      <!--                      <iframe src="http://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true"  frameborder="0"></iframe>-->
                        </div>
                    <div v-else-if="sample_image" class="uploaded_file_view" style="margin: 10px auto">
                            <img src="../images/mock-reciept.jpg"/>
                        </div>
                </div>
            </div>
          <!--            <div class="row" v-if="url">-->
          <!--                <div class="col-md-12 col-sm-10">-->
          <!--                         <div class="panel unset-bottom-panel">-->
          <!--                            <div class="btn btn-info width-unset " v-bind:style="{'background-color':system_settings.accent_color}">-->
          <!--                                <div class="btn_upload">-->
          <!--                                    <input type="file" @change="onFileChanged" name="">-->
          <!--                                    {{$t('Upload or Change')}}-->
          <!--                                </div>-->

          <!--                            </div>-->
          <!--                        </div>-->
          <!--                </div>-->
          <!--            </div>-->





            </div>
        </div>

    </div>
    <div class="bottom-actions">
        <div v-if="!error_warning" class="form-group text-center pr-4 pt-4 float-right">
          <button @click="onUpload"
                  :disabled="loading"
                  v-bind:style="{'background-color':system_settings.accent_color,color:  system_settings.accent_text_color}"
                  type="submit" class="btn btn-info next px-4 py-2">{{ $t('CONTINUE') }}
                    </button>
        </div>
        <div v-if="error_warning" class="form-group text-center pr-4 pt-4 float-right">
          <button @click="warningVerify"
                  :disabled="loading"
                  type="submit" class="btn btn-warning next px-4 py-2">{{ $t('WARNING_CONTINUE') }}
                    </button>
        </div>
      <div class="form-group text-center pl-4 pt-4 float-left start-over">
        <a href="/"
           :disabled="loading"
        >{{ $t('START_OVER') }}
        </a>
        </div>
    </div>
</span>
</template>
<script>
import Stepper from './StepperBar.vue';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`
export default {
  data() {
    return {
      fileUrl: null,
      pageRendering: false,
      pageCount: null,
      pageNumPending: null,
      pdfDoc: null,
      selectedFile: null,
      url: false,
      error: false,
      loading: false,
      error_warning: false,
      status: true,
      fieldTextUpload: 'UPLOAD_RECEIPT_IMAGE',
      file_empty_error: false,
      sample_image: false,
      valid_file_types: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'],
      max_file_size: 5242880,
      file_error: false,
      pageNum: 1
    }
  },
  props: {

    width: {
      type: Number,
      default: 350,
    },
    height: {
      type: Number,
      default: 350,
    },
  },
  methods: {
    queueRenderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num);
      }
    },

    onPrevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.queueRenderPage(this.pageNum);
    },
    // document.getElementById('prev').addEventListener('click', onPrevPage);

    onNextPage() {
      if (this.pageNum >= this.pdfDoc.numPages) {
        return;
      }
      this.pageNum++;
      this.queueRenderPage(this.pageNum);
    },
    // document.getElementById('next').addEventListener('click', onNextPage);
    async onFileChanged(event) {
      this.fileUrl = null;
      this.file_error = false;
      this.error = false;
      this.selectedFile = event.target.files[0];

      if (this.selectedFile.size !== undefined && this.selectedFile.size > this.max_file_size) {
        this.error = 'FILE_TOO_LARGE';
        this.file_error = true;
      }
      if (this.selectedFile.type !== undefined && !this.valid_file_types.includes(this.selectedFile.type)) {
        this.error = 'INVALID_FILE_TYPE';
        this.file_error = true;
      }
      var blobD = new Blob([this.selectedFile], {type: this.selectedFile?.type});
      this.url = URL.createObjectURL(blobD);
      console.log(this.selectedFile)
      if (this.selectedFile.type !== undefined && this.selectedFile.type === 'application/pdf') {
        this.fileUrl = this.url;
        // this.renderThumbnails()
        this.renderPage(this.pageNum);

      }
      this.fieldTextUpload = 'CHANGE_RECEIPT_TEXT';
      this.error_warning = false;
    },
    async renderPage(num) {
      this.pageRendering = true;
      // Using promise to fetch the page
      const worker = new pdfjsLib.PDFWorker()
      let pdf = await pdfjsLib
          .getDocument({url: this.fileUrl, worker: worker})
          .promise.then((pdf) => pdf)
      this.pdfDoc = await pdf;
      this.pageCount = this.pdfDoc.numPages;
      const page = await pdf.getPage(num)
      let viewport = page.getViewport({scale: 1.0})
      viewport = page.getViewport({scale: this.width / viewport.width})
      let canvas = document.getElementById(this._uid)
      canvas.height = viewport.height
      canvas.width = viewport.width
      const context = canvas.getContext('2d')


      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      var task = page.render(renderContext)
      var self = this
      await task.promise.then(function () {
        self.fetchImage()
      });

      // await page.render(renderContext).then;
      this.pageRendering = false;
      if (this.pageNumPending !== null) {
        this.renderPage(this.pageNumPending);
        this.pageNumPending = null;
      }
    },
    async fetchImage() {
      function urltoFile(url, filename, mimeType) {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                })
        );
      }

      var fName = this.selectedFile.name.replace('.pdf', '.png');
      let canvas = document.getElementById(this._uid)
      console.log(canvas.toDataURL());
      this.selectedFile = await urltoFile(canvas.toDataURL(), fName);
      // // console.log( document.getElementById(this._uid))
      // var blobD = new Blob([this.selectedFile], {type: this.selectedFile?.type});
      // this.url = URL.createObjectURL(blobD);
      // this.fileUrl = null
    },
    // for pdf thumbnails
    async renderThumbnails() {
      console.log(this._uid)
      const worker = new pdfjsLib.PDFWorker()
      let pdf = await pdfjsLib
          .getDocument({url: this.fileUrl, worker: worker})
          .promise.then((pdf) => pdf)
      const page = await pdf.getPage(1)
      let viewport = page.getViewport({scale: 1.0})
      viewport = page.getViewport({scale: this.width / viewport.width})
      let canvas = document.getElementById(this._uid)
      canvas.height = viewport.height
      canvas.width = viewport.width
      const context = canvas.getContext('2d')

      await page.render({canvasContext: context, viewport: viewport})
    },
    onUpload() {
      if (!this.selectedFile) {
        this.file_empty_error = true;
        this.loading = false;
      } else if (!this.file_error) {
        this.loading = true;
        const formData = new FormData()
        formData.append('voucher', this.selectedFile, this.selectedFile.name)

        this.file_empty_error = false;
        this.error = false;
        this.error_warning = false;
        this.axios
            .post('/upload-voucher', formData)
            .then(response => {
              if (response.data.statusCode === 0) {
                this.$router.push({name: 'products'});
                this.error_warning = false;
              } else if (response.data.statusCode === 3) {
                this.error_warning = response.data.debug;
              } else {
                this.error = response.data.debug;
                this.fieldTextUpload = 'CHANGE_RECEIPT_TEXT';
              }
            })
            .catch(error => console.log(error))
            .finally(() => this.loading = false)
      }

    },
    startOver() {
      this.$router.push({name: 'home'});
    },
    warningVerify() {
      this.$router.push({name: 'products'});
    }
  },
  computed: {
    system_settings() {
      if (this.$store.state.systemSettings.length === 0) {
        return {ascent_color: '', ascent_text_color: ''}

      } else {
        return this.$store.state.systemSettings.siteDefaultContent;
      }
    }

  },
  components: {
    Stepper
    // StepNavigation
  },
}
</script>

<style>
.the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>