import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from './plugins/axios'


// Vue.use(VueI18n)

// export const i18n = new VueI18n({
//     locale: 'en', // set locale
//     fallbackLocale: 'en',
//     // set locale messages
// })

// const loadedLanguages = []

// function setI18nLanguage(lang) {
//     i18n.locale = lang
//     axios.defaults.headers.common['Accept-Language'] = lang
//     document.querySelector('html').setAttribute('lang', lang)
//     return lang
// }

//
// export function loadLanguageAsync(lang) {
//     if (loadedLanguages.includes(lang)) {
//         if (i18n.locale !== lang) setI18nLanguage(lang)
//         return Promise.resolve()
//     }
//     return axios.get(basePath + '/api/system-translations').then(response => {
//         let msgs = response.data
//         var lang = msgs.locale
//         var flang = msgs.fallbackLocale
//         loadedLanguages.push(lang)
//         i18n.setLocaleMessage(lang, msgs.default)
//         setI18nLanguage(lang)
//         i18n.fallbackLocale = flang
//     })

// return axios.get(basePath + '/api/system-translations', {
//     headers: {
//         'Accept': 'application/json'
//     }
// }).then(response => {
//     // return  response.data
//     let msgs = response.data
//     var lang = msgs.locale
//     var flang = msgs.fallbackLocale
//     loadedLanguages.push(lang)
//     i18n.setLocaleMessage(lang, msgs.default)
//     setI18nLanguage(lang)
//     i18n.fallbackLocale = flang
// }).catch((error) => {
//     console.log('error' + error);
// })
// }

// function loadLocaleMessages() {
// // var messages = {}
// //     return axios.get(basePath + '/api/system-translations', {
// //         headers: {
// //             'Accept': 'application/json'
// //         }
// //     }).then(response => {
// //         return response.data
// //     }).catch((error) => {
// //         console.log('error' + error);
// //     })
//     // console.log(messages);
//     // return messages;
// }

// export default new VueI18n(
//     loadLocaleMessages()
// )
//i18n-setup.js

Vue.use(VueI18n)
// const messages = {}
export const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    // messages // set locale messages
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    // console.log(lang);
    // console.log(i18n.locale);
    // If the same language
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)
        return Promise.resolve()
    }
// console.log(lang);
    // If the language hasn't been loaded yet
    return axios.post('/system-translations', {
        'lang': lang
    }, {
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => {

        i18n.setLocaleMessage(lang, response.data.default)
        loadedLanguages.push(lang)
        setI18nLanguage(lang)

    }).catch((error) => {
        console.log('error' + error);
    })
}

// // router.js
