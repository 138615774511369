<template>
    <div class=" logo col-md-12 text-center mb-5">
        <img v-if="$route.name !== 'home' && system_settings.logo.length >0" :src="system_settings.logo" height="80"/>
        <img v-if="$route.name === 'home' && system_settings.logo.length >0" :src="system_settings.logo" height="150"/>
<!--        <img v-if="$route.name === 'home' && system_settings.logo.length === 0" src="../images/siteLogo.png"-->
<!--             height="150"/>-->
<!--        <img v-if="$route.name !== 'home' && system_settings.logo.length === 0" src="../images/siteLogo.png"-->
<!--             height="80"/>-->
    </div>
</template>
<script>
    export default {
        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {logo: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }
        }
    }
</script>