<template>
    <span>
    <div role="main">
        <div class="action-page py-5">
            <div class="container">

<div id="cover-spin" v-if="loadingLan"></div>
<!--                <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">-->
                <!--&lt;!&ndash; <a href="#" @click="loadTranslations(pickedActualLanguage,true)"  v-bind:style="{ color: system_settings.ascent_color }"  class="act-lan">{{pickedActualLanguage.name}}</a>&ndash;&gt;-->
                <!--                <div class="btn-group  ml-auto " style="border:none">-->

                <!--  <label v-if="Object.keys(loadedLocale).length > 0"-->
                <!--         class="btn btn-secondary">{{loadedLocale.name}}</label>-->
                <!--  <label v-else class="btn btn-secondary">{{pickedLanguage.name}}</label>-->
                <!--  <button type="button" style="padding-left: 1px; "-->
                <!--          class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true"-->
                <!--          aria-expanded="false">-->
                <!--    <span class="sr-only">Toggle Dropdown</span>-->
                <!--  </button>-->
                <!--  <div class="dropdown-menu">-->
                <!--    <a v-for="lan in languages" @click="loadTranslations(lan, false)" v-bind:key="lan.code" :value="lan.code"-->
                <!--       class="dropdown-item" href="#">{{lan.name}}</a>-->
                <!--</div>-->
                <!--                    &lt;!&ndash;</div>&ndash;&gt;-->
                <!--                    &lt;!&ndash;&lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
                <!--</div>-->
                <!--</div>-->
                    <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
   <div class="btn-group ml-auto">
  <button v-if="Object.keys(loadedLocale).length > 0" type="button" class="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{loadedLocale.name}}
  </button>
       <button v-else type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
    {{pickedLanguage.name}}
  </button>
  <div class="dropdown-menu">
    <a v-for="lan in languages" @click="loadTranslations(lan, false)" v-bind:key="lan.code" :value="lan.code"
       class="dropdown-item" href="#">{{lan.name}}</a>
  </div>
</div>
</div>


                <div class="row">
                   <logo></logo>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-12 shadow-sm">
<!--                            <div id="cover-spin" v-if="loading"></div>-->

                           <header-text></header-text>

                            <router-view></router-view>


                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <footer-text>
    </footer-text>
    </span>
</template>

<script>
    import Logo from './components/PartnerLogo.vue';
    import HeaderText from './components/HeaderText.vue';
    import FooterText from './components/FooterText.vue';

    import {loadLanguageAsync} from './translations'
    // import StepNavigation from './components/StepNavigation.vue';

    export default {
        data() {
            return {
                loadedLocale: {},
                loadingLan: false,
                lanButtonClass: 'btn btn-default'
            }
        },
        computed: {
            languages() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {}

                } else {
                    return this.$store.state.systemSettings.languages;
                }
            },
            pickedLanguage() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {}

                } else {

                    return this.$store.state.systemSettings.pickedLanguage;
                }
            },
            pickedActualLanguage() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {}

                } else {

                    return this.$store.state.systemSettings.pickedActualLanguage;
                }
            },
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {ascent_color: '', ascent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            }
        },
        methods: {

            async loadTranslations(lang, loadDefault) {
                this.lanButtonClass = 'btn btn-primary'
                if (!loadDefault) {
                    this.loadedLocale = lang
                } else {
                    this.loadedLocale = '';
                }
                this.loadingLan = true
                await loadLanguageAsync(lang.code).then(
                    this.loadingLan = false
                )
            },

        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.toUpperCase()
            }
        },
        components: {
            Logo,
            HeaderText,
            FooterText,
            // StepNavigation
        },
        watch: {
            async system_settings() {
                console.log('here');
                console.log(this.$store.state.systemSettings.locale)
                this.loadingLan = true
                await loadLanguageAsync(this.$store.state.systemSettings.locale).then(
                    this.loadingLan = false
                )
            }
        }


    }
</script>
<style>
    @import 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';
    /*@import 'http://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css';*/
    @import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css';
    /*@import 'https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.4.0/css/bootstrap4-toggle.min.css';*/
    @import '~vue-datetime/dist/vue-datetime.css';
    /*@import 'https://unpkg.com/nprogress@0.2.0/nprogress.css';*/
    @import '~vue-select/dist/vue-select.css';
    /*@import "~vue-select/src/scss/vue-select.scss";*/
    @import './css/style.css';
    @import "https://fonts.googleapis.com/css?family=Roboto";

    #cover-spin {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;

    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    /*li .fa {*/
    /*    padding-right: 10px;*/
    /*}*/

    .title-font {
        font-family: "232MKSDRoundLightRegular", Arial, Helvetica, sans-serif;
        font-weight: bold;
    }

    .logo img {
        max-width: 100%;
        max-height: 100%;
    }

    /*li {*/
    /*    !*padding-right: 20px;*!*/
    /*}*/

    .title {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .success {
        color: #4CAF50;
    }

    .next {
        width: 100%;
    }

    .width-unset {
        width: unset;
    }

    .header-title-info {

        text-decoration: none;
        font-size: 20px;
        text-transform: none;

    }

    .li-info {
        margin: 17px 0;
    }

    .title-gap {
        padding-top: 70px;
    }

    /* Green */
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    #cover-spin::after {
        content: '';
        display: block;
        position: absolute;
        left: 48%;
        top: 40%;
        width: 40px;
        height: 40px;
        border-style: solid;
        border-color: black;
        border-top-color: transparent;
        border-width: 4px;
        border-radius: 50%;
        -webkit-animation: spin .8s linear infinite;
        animation: spin .8s linear infinite;
    }

    .panel {
        margin: 1px auto 40px;
        max-width: 300px;
        text-align: center;
    }

    .unset-bottom-panel {
        margin-bottom: unset;
        -webkit-box-shadow: none;
    }

    .button_outer {
        background: #83ccd3;
        border-radius: 30px;
        text-align: center;
        height: 50px;
        width: 160px;
        display: inline-block;
        transition: .2s;
        position: relative;
        overflow: hidden;
        /*margin-right:90px;*/
    }

    .btn_upload {
        /*padding: 17px 30px 12px;*/
        color: #fff;
        text-align: center;
        position: relative;
        display: inline-block;
        overflow: hidden;
        z-index: 3;
        white-space: nowrap;
        margin-top: 7px;
    }

    .btn_upload input {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        /*width: 100%;*/
        /*height: 105%;*/
        cursor: pointer;
        opacity: 0;
    }

    .file_uploading {
        width: 100%;
        height: 10px;
        margin-top: 20px;
        background: #ccc;
    }

    .file_uploading .btn_upload {
        display: none;
    }

    .processing_bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-radius: 30px;
        background: #83ccd3;
        transition: 3s;
    }

    .file_uploading .processing_bar {
        width: 100%;
    }

    .success_box {
        display: none;
        width: 50px;
        height: 50px;
        position: relative;
    }

    .success_box:before {
        content: '';
        display: block;
        width: 9px;
        height: 18px;
        border-bottom: 6px solid #fff;
        border-right: 6px solid #fff;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        left: 17px;
        top: 10px;
    }

    .file_uploaded .success_box {
        display: inline-block;
    }

    .file_uploaded {
        margin-top: 0;
        width: 50px;
        background: #83ccd3;
        height: 50px;
    }

    .uploaded_file_view {
        max-width: 300px;
        margin: 40px auto;
        text-align: center;
        position: relative;
        transition: .2s;

        border: 2px solid #ddd;
        padding: 15px;
    }

    /*.uploaded_file_view1 {*/
    /*    max-width: 300px;*/
    /*    text-align: center;*/
    /*    position: relative;*/
    /*    transition: .2s;*/

    /*    border: 2px solid #ddd;*/
    /*    padding: 15px;*/
    /*}*/

    .file_remove {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
        position: absolute;
        background: #aaa;
        line-height: 30px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        right: -15px;
        top: -15px;
    }

    .file_remove:hover {
        background: #222;
        transition: .2s;
    }

    .uploaded_file_view img {
        max-width: 100%;
    }

    /*.container {*/
    /*    background-color: #fff;*/
    /*}*/

    .uploaded_file_view.show {
        opacity: 1;
    }


    .stepper-title {

        font-family: "232MKSDRoundLightRegular", Arial, Helvetica, sans-serif;
        font-size: 11px;
        line-height: 17px;
        padding-top: 10px;
        width: 60px;
        text-align: center;
        margin-left: -10px;
        /*margin-left: 10px;*/
    }

    .field-info {
        /*text-align: center;*/
        margin-top: -10px;
        padding-bottom: 20px;
    }

    /*timeline view*/
    .timeline {
        list-style: none;
        padding: 20px 0 20px;
        position: relative;
    }

    .timeline:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;
    }

    .timeline > li {
        margin-bottom: 20px;
        position: relative;
    }

    .timeline > li:before,
    .timeline > li:after {
        content: " ";
        display: table;
    }

    .timeline > li:after {
        clear: both;
    }

    .timeline > li:before,
    .timeline > li:after {
        content: " ";
        display: table;
    }

    .timeline > li:after {
        clear: both;
    }

    .timeline > li > .timeline-panel {
        width: 45%;
        float: left;
        border: 1px solid #d4d4d4;
        border-radius: 2px;
        padding: 20px;
        position: relative;
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    }

    .timeline > li > .timeline-panel:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " ";
    }

    .timeline > li > .timeline-panel:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " ";
    }

    .timeline > li > .timeline-badge {
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -25px;
        background-color: #999999;
        z-index: 100;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    .timeline > li.timeline-inverted > .timeline-panel {
        float: right;
    }

    .timeline > li.timeline-inverted > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    .timeline > li.timeline-inverted > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .timeline-badge.primary {
        background-color: #2e6da4 !important;
    }

    .timeline-badge.success {
        background-color: #3f903f !important;
    }

    .timeline-badge.warning {
        background-color: #f0ad4e !important;
    }

    .timeline-badge.danger {
        background-color: #d9534f !important;
    }

    .timeline-badge.info {
        background-color: #1a237e !important;
    }

    .timeline-title {
        margin-top: 0;
        color: inherit;
    }

    .timeline-body > p,
    .timeline-body > ul {
        margin-bottom: 0;
    }

    .timeline-body > p + p {
        margin-top: 5px;
    }

    .header-info {
        padding-bottom: 20px;
        padding-top: 50px;
    }

    /**
    stepper new
     */


    #stepper-wrap {
        margin: 3em 1em 3em 3em;
        top: 100;
        position: relative;

    }

    .stepper-line {
        height: 6px;
        display: inline-block;
        width: 25%;
        background-color: #e5e5e5;
        position: relative;
        margin-bottom: 10px;

    }

    .stepper-line.active {
        background-color: #1a237e;
    }

    .marker {
        width: 50px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        border-radius: 100px;
        background: #fff;
        border: 2px solid #e0e0e0;
        z-index: 2;
        position: absolute;
        left: 0;
        text-align: center;
        font-size: 20px;
        margin-left: -35px;
    }


    .marker.active {
        border: 2px solid #1a237e;
        font-weight: bold;

    }

    .marker.active i {
        color: #1a237e;
    }


    .mfirst {
        top: -25px;
    }

    .m2 {
        top: -25px;
        left: 25%
    }

    .m3 {
        top: -25px;
        left: 50%
    }

    .m4 {
        top: -25px;
        left: 75%
    }

    .mlast {
        top: -25px;
        left: 100%
    }

    .act-lan {
        margin-left: 70%;
        padding-top: 8px;
    }

    .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
        /*color: #ffffff;*/
        background-color: #ffffff !important;
        border-color: #ffffff !important;
    }

    .dropdown-menu {
        min-width: auto;
    }

    .bottom-actions {

        height: 70px;
        background-color: #f7f7f7;
        border-top: 1px solid rgba(0, 0, 0, .125);

        border-bottom: 80px solid #f7f7f7;
    }

    .btn-info {
        background-color: #6671E3;
    }

    .start-over {
        margin-top: 0.5rem;
    }

    .btn {
        font-size: unset !important;
    }

    /* end */
    @media (max-width: 767px) {
        ul.timeline:before {
            left: 40px;
        }

        ul.timeline > li > .timeline-panel {
            width: calc(100% - 90px);
            width: -moz-calc(100% - 90px);
            width: -webkit-calc(100% - 90px);
        }

        ul.timeline > li > .timeline-badge {
            left: 15px;
            margin-left: 0;
            top: 16px;
        }

        ul.timeline > li > .timeline-panel {
            float: right;
        }

        ul.timeline > li > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        ul.timeline > li > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }
    }


</style>