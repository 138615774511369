<template>
    <span>
    <div v-if="$route.name === 'home'" class="card-header text-center">
        <h1 class="home-title" style="margin-top: unset;margin-bottom: 25px; ">{{$t('CASHBACK')}} <span class="colored"
                                                        v-bind:style="{color:  system_settings.accent_color}">{{$t('REDEMPTION')}}</span>
        </h1>
    </div>
    <div v-if="$route.name !== 'home'" class="card-header text-center sub-header">
    <h1 class="home-title"  v-if="$route.name === 'upload-voucher'" style="font-size: 25px ;">{{$t('UPLOAD')}} <span class="colored"
                                                                                                                     v-bind:style="{color:  system_settings.accent_color}"> {{$t('RECEIPT')}} </span> - {{$t('FOR')}}  {{validation_code}}
        </h1>
        <h1 class="home-title"  v-else-if="$route.name === 'products'"  style="font-size: 25px ;">{{$t('PURCHASE')}}  <span class="colored"
                                                                                  v-bind:style="{color:  system_settings.accent_color}">{{$t('DETAILS')}}</span> - {{$t('FOR')}}  {{validation_code}}
        </h1>
        <h1 class="home-title"  v-else-if="$route.name === 'payment-details'"  style="font-size: 25px ;">{{$t('BANK')}}  <span class="colored"
                                                                                  v-bind:style="{color:  system_settings.accent_color}">{{$t('DETAILS')}}</span> - {{$t('FOR')}}  {{validation_code}}
        </h1>
        <h1 class="home-title"  v-else-if="$route.name === 'recap'"  style="font-size: 25px ;">{{$t('CONFIRMATION')}} -  for  {{validation_code}}
        </h1>
        <h1 class="home-title"  v-else style="font-size: 25px ;">{{$t('CASHBACK')}} <span class="colored"
                                                                                  v-bind:style="{color:  system_settings.accent_color}">{{$t('REDEMPTION')}}</span> - {{$t('FOR')}}  {{validation_code}}
        </h1>

</div>
    </span>
</template>

<script>
    export default {
        computed: {
            system_settings() {
                if (this.$store.state.systemSettings.length === 0) {
                    return {ascent_text_color: ''}

                } else {
                    return this.$store.state.systemSettings.siteDefaultContent;
                }
            },
            validation_code(){
                if (this.$store.state.validationCode.length === 0) {
                    return {ascent_text_color: ''}

                } else {
                    return this.$store.state.validationCode;
                }
            }
        }
    }
</script>
<style>

    .sub-header{
        padding-top: 20px !important;
        padding-bottom: 10px;
    }
</style>